<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>

<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    tipsList,
    editStatus,
    deleteTips
  } from "@/api/web/tips.js";


  /**
   * 公共信息
   */
  export default {
    components: {
      Layout,
      PageHeader,

    },
    data() {
      return {
        title: "推送信息 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        query: {
          keyword: "",
          pageNum: 1,
          pageSize: 20
        },
        checked: true,
        list: [],
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0,
        // 发布状态
        statusLsit: [{
            name: "已发布",
            val: "1",
          },
          {
            name: "未发布",
            val: "0",
          },
        ],

      };
    },

    mounted() {
      this.getList();
    },
    computed: {
      rows() {
        return this.list.length;
      },
    },
    methods: {
      /** 查询公共信息列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        tipsList(this.query).then(res => {
          if (res.status) {
            this.list = res.data
            this.total = res.total
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      editTipsStatus(val) {
        let formDate = JSON.parse(JSON.stringify(val))
        editStatus(formDate).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message,
            })
            this.getList()
          }
        })
      },
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteTips(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      /** 前往编辑推送信息 */
      editTips(tid) {
        this.$router.push({
          path: '/admin/tipsPublish',
          name: 'tipsPublish',
          params: {
            tid: tid
          }
        })
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">

            <div class="pb-3 check-table-top">
              <form class="flexList" style="flex: 1;">
                <el-select class=" mr-2 " style="width:8%;" clearable placeholder="请选择" v-model="query.fbzt"
                  size="small">
                  <el-option v-for="(item, i) in statusLsit" :label="item.name" :value="item.val" :key="i">
                  </el-option>
                </el-select>
                <input placeholder="输入标题" v-model="query.keyword" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />
                <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="searchClick()"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</b-button>
                <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="editTips()"><i
                    class="iconfont mr-2 icon-plus-circle1"></i>添加推送信息</b-button>
              </form>
            </div>
            <div class="table-responsive border">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:4%"> 序号</th>
                    <th style="width:20%">推送信息标题</th>
                    <th style="width:6%">限制指定阅读</th>
                    <th style="width:6%">已指定人数</th>
                    <th style="width:6%">查看记录</th>
                    <th style="width:6%">查看次数</th>
                    <th style="width:6%">发布状态</th>
                    <th style="width:6%">编辑人</th>
                    <th style="width:7%">编辑时间</th>
                    <th style="width:4%" class="tab-icon">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in list" :key="index">
                    <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>{{obj.bt}}</td>
                    <td>
                      <a :href="'/admin/tipsScan?tid='+obj.tid+'&zdzt=1'">导入名单</a>
                    </td>
                    <td>{{obj.zdrs}}</td>
                    <td>
                      <a :href="'/admin/tipsScan?tid='+obj.tid+'&ydzt=1'">浏览名单</a>
                    </td>
                    <td>{{obj.ckcs}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.fbzt" switch class="mt-1 switch-check" :value="true"
                        :unchecked-value="false" @change="editTipsStatus({'tid':obj.tid,'fbzt':obj.fbzt})">
                      </b-form-checkbox>
                    </td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{obj.modifyTime != null ? obj.modifyTime.substring(0,16) : ""}}</td>
                    <td class="tab-icon">
                      <i class="iconfont icon-edit-two" title="修改" @click="editTips(obj.tid)"></i>
                      <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj.tid,obj.bt)"></i>
                    </td>
                  </tr>

                </tbody>


              </table>

            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-size="perPage" layout="total, sizes,jumper, prev, pager, next"
                :total="total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
